
import Vue from "vue";

export default Vue.extend({
  name: "InputRange",

  props: {
    value: {
      required: false,
      type: String
    },
    label: {
      required: false,
      type: String,
      default: ""
    }
  },

  data: () => ({
    values: ["", ""] as Array<string>
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.values = this.value.split(",") as Array<string>;
        } else {
          this.values = ["", ""];
        }
      }
    }
  },

  methods: {
    changeData(): void {
      this.$emit("input", this.values.join(","));
    }
  }
});
